import React from "react";
import vxxxss from "../images/comming.png";
import vcbxx from "../images/re.png";

function Kkg() {
    return (
        <section className="FSX">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="commingxc">
                <img src={vxxxss} />
                </div>
                <div className="comc">
                <img src={vcbxx} />
                </div>
            </div>
            </div>
            </div>
            </section>

    );
  }
  
  export default Kkg;