import React from "react";
import Buy from "../component/Buy";


function Buybugs() {
  return (
    <>
      <Buy />
    </>
  );
}

export default Buybugs;
